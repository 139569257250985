import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {InteractionType, IPublicClientApplication, PublicClientApplication} from '@azure/msal-browser';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorAuthRequest,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalRedirectComponent,
    MsalService
} from "@azure/msal-angular";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './pages/home/home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {EmployeeOverviewComponent} from './pages/employee/employee-overview/employee-overview.component';
import {
    DxAutocompleteModule,
    DxBulletModule,
    DxButtonModule,
    DxChartModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDateRangeBoxModule,
    DxDrawerModule,
    DxDropDownBoxModule,
    DxDropDownButtonModule,
    DxFileUploaderModule,
    DxFormModule,
    DxGalleryModule,
    DxHtmlEditorModule,
    DxListModule,
    DxMapModule,
    DxMenuModule,
    DxNumberBoxModule,
    DxPieChartModule,
    DxPopupModule,
    DxProgressBarModule,
    DxRadioGroupModule,
    DxResponsiveBoxModule,
    DxSchedulerModule,
    DxSelectBoxModule,
    DxTabsModule,
    DxTagBoxModule,
    DxTemplateModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxToolbarModule,
    DxTreeListModule,
    DxTreeViewModule,
    DxValidationSummaryModule,
    DxValidatorModule
} from "devextreme-angular";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CookieService} from 'ngx-cookie-service';
import {OrganisationUnitOverviewComponent} from './pages/organization-unit/organisation-unit-overview/organisation-unit-overview.component';
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {MatRadioModule} from "@angular/material/radio";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatTabsModule} from "@angular/material/tabs";
import {ToastrModule} from "ngx-toastr";
import {EnumKeysPipe} from './pipes/enum-keys.pipe';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {EmployeeTableComponent} from './shared-components/employee-table/employee-table.component';
import {TrainingImageFallbackDirective} from './directives/training-image-fallback.directive';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {OrganisationUnitTreeDropDownComponent} from './components/shared/organisation-unit-tree-drop-down/organisation-unit-tree-drop-down.component';
import {TrainingGroupDropDownComponent} from './components/shared/training-group-drop-down/training-group-drop-down.component';
import {OverlayModule} from "@angular/cdk/overlay";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {SpinnerOverlayComponent} from './shared-components/spinner-overlay/spinner-overlay.component';
import {GoogleMapsComponent} from './components/shared/google-maps/google-maps.component';
import {GoogleMapsModule} from "@angular/google-maps";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {EventLocationComponent} from './shared-components/event-location/event-location.component';
import {IntegrationsComponent} from './pages/integrations/integrations.component';
import {WebexComponent} from './pages/integrations/webex/webex.component';
import {TrainingOverviewComponent} from './pages/training/training-overview/training-overview.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {DatePipe, registerLocaleData} from "@angular/common";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import {AreTrainingDatesDifferentDaysPipe} from './pipes/are-training-dates-different-days.pipe';
import {FirstStartDatePipe} from './pipes/first-start-date.pipe';
import {LastEndDatePipe} from './pipes/last-end-date.pipe';
import {TrainingDatePipe} from './pipes/training-date.pipe';
import {HeaderComponent} from './components/header/header.component';
import {TrainingDateSelectionComponent} from './components/training-date-selection/training-date-selection.component';
import {CompletedEventTableComponent} from './components/completed-event-table/completed-event-table.component';
import {TrainingTypeDisplayPipe} from './pipes/training-type-display.pipe';
import {DownloadComponent} from './download/download.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatMenuModule} from "@angular/material/menu";
import {AddExternalCertificateDialogComponent} from './components/add-external-certificate-dialog/add-external-certificate-dialog.component';
import {ConfirmationDialogComponent} from './components/shared/confirmation-dialog/confirmation-dialog.component';
import {GotowebinarComponent} from './pages/integrations/gotowebinar/gotowebinar.component';
import {TrainingCalendarComponent} from './pages/training/training-calendar/training-calendar.component';
import {TrainingMessageComponent} from './pages/training/training-invite/training-message.component';
import {ElearningMessageComponent} from './pages/manage-elearning/elearning-invite/elearning-message.component';
import {MatChipsModule} from "@angular/material/chips";
import {InvitePersonSelectDialogComponent} from './pages/training/training-invite/invite-person-select-dialog/invite-person-select-dialog.component';
import {SelectablePersonComponent} from './shared-components/selectable-person/selectable-person.component';
import {IsTrainingDateDifferentDaysPipe} from './pipes/is-training-date-different-days.pipe';
import {TrainingDetailEventMemberComponent} from './components/training-detail-event-member/training-detail-event-member.component';
import {ImagePickerComponent} from './shared-components/image-picker/image-picker.component';
import {SideNavComponent} from './components/side-nav/side-nav.component';
import {PageTitleComponent} from './components/shared/page-title/page-title.component';
import {PageComponent} from './components/shared/page/page.component';
import {PanelComponent} from './components/shared/panel/panel.component';
import {TableComponent} from './components/shared/table/table.component';
import {EmployeeFormComponent} from './pages/employee/employee-form/employee-form.component';
import {OrganizationUnitFormComponent} from './pages/organization-unit/organization-unit-form/organization-unit-form.component';
import {ExternalContactsOverviewComponent} from './pages/external-contacts/external-contacts-overview/external-contacts-overview.component';
import {ExternalContactsFormComponent} from './pages/external-contacts/external-contacts-form/external-contacts-form.component';
import {MyTrainingsComponent} from './pages/training/my-trainings/my-trainings.component';
import {TrainingInfoComponent} from './pages/training/training-info/training-info.component'
import {CourseInfoComponent} from './pages/training/course-info/course-info.component'
import {TrainingCardComponent} from './components/training-card/training-card.component'
import {ReadMoreComponent} from './components/read-more/read-more.component'
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {AdministrationComponent} from './pages/administration/administration.component';
import {TrainingGroupsDetailsComponent} from './pages/administration/training-groups-details/training-groups-details.component';
import {TrainingCategoriesDetailsComponent} from './pages/administration/training-categories-details/training-categories-details.component';
import {TimePickerComponent} from './components/shared/time-picker/time-picker.component';
import {TrainingFormComponent} from './pages/training/training-form/training-form.component';
import {ManageElearningOverviewComponent} from './pages/manage-elearning/manage-elearning-overview/manage-elearning-overview.component';
import {EditManageElearningComponent} from './pages/manage-elearning/edit-manage-elearning/edit-manage-elearning.component';
import {VideoUploaderComponent} from './components/shared/video-uploader/video-uploader.component';
import {TrainingEmployeePopupComponent} from './components/training-employee-popup/training-employee-popup.component';
import {TrainingTemplateFormComponent} from './pages/training-template/training-template-form/training-template-form.component';
import {GeneralSettingsComponent} from './pages/general-settings/general-settings.component';
import {AttendeesListComponent} from './components/shared/attendees-list/attendees-list.component';
import {TrainingCompleteComponent} from './pages/training/training-complete/training-complete.component';
import {TrainingFeedbackComponent} from './pages/training/training-feedback/training-feedback.component';
import {NgxPrintModule} from 'ngx-print';
import {ChangePasswordModalComponent} from './components/change-password-modal/change-password-modal.component';
import {TrainingOverviewPopupComponent} from './components/training-overview-popup/training-overview-popup.component';
import {TrainingCancelDialogComponent} from './pages/training/training-cancel-dialog/training-cancel-dialog.component';
import {AttendeeListComponent} from "./pages/training/training-complete/attendee-list/attendee-list.component";
import {CourseQuestionsModalComponent} from "./components/course-questions-modal/course-questions-modal.component";
import {Configuration} from "../assets/Configuration";
import {environment, msalConfig, protectedResources} from "../environments/environment";
import {CourseQuestionsComponent} from './pages/training/course-questions/course-questions.component';
import {CourseQuestionComponent} from './pages/training/course-questions/course-question/course-question.component';
import {FeedbackComponent} from './pages/feedback/feedback.component';
import {MyInvitationComponent} from "./pages/my-invitation/my-invitation.component";
import {AcceptInvitationModalComponent} from "./components/accept-invitation-modal/accept-invitation-modal.component";
import {TestComponent} from "./pages/test/test.component";
import {LoginComponent} from "./pages/login/login.component";
import {ComponentLoaderComponent} from "./components/shared/component-loader/component-loader.component";
import {ImportDataComponent} from "./pages/administration/import-data/import-data.component";
import {TrainingCatalogueOverviewComponent} from "./pages/training-catalogue/training-catalogue-overview/training-catalogue-overview.component";
import {TrainingCatalogueCardComponent} from "./components/training-catalogue-card/training-catalogue-card.component";
import {TrainingCatalogueInfoComponent} from "./pages/training-catalogue/training-catalogue-info/training-catalogue-info.component";
import {MergeEmployeesModalComponent} from "./components/merge-employees-modal/merge-employees-modal.component";
import {TrainingSaveCalendarModalComponent} from "./components/training-save-calendar-modal/training-save-calendar-modal.component";
import {IntegrateWbdModalComponent} from "./components/integrate-wbd-modal/integrate-wbd-modal.component";
import {ReportingIddComponent} from "./pages/reporting/reporting-idd/reporting-idd.component";
import {ReportingComplianceComponent} from "./pages/reporting/reporting-compliance/reporting-compliance.component";
import { debug } from 'console';


registerLocaleData(localeDe, 'de', localeDeExtra);

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();

    let adb2cTemplate = environment.defaultAdb2cTemplate;
    if (window.location.hostname.endsWith(".skeddle.com")) {
        adb2cTemplate = window.location.hostname.split(".")[0];
    }

    protectedResourceMap.set(
        Configuration.getApiBaseUrl(),
        protectedResources.scopes
    );

    return {
        interactionType: InteractionType.Redirect,
        authRequest: ({
            scopes: ["profile"],
            extraQueryParameters: {"customer": adb2cTemplate }
        } as MsalInterceptorAuthRequest),
        protectedResourceMap
    };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {

    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ["profile"]
        }
    };
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        HomeComponent,
        DashboardComponent,
        EmployeeOverviewComponent,
        OrganisationUnitOverviewComponent,
        EnumKeysPipe,
        EmployeeTableComponent,
        TrainingImageFallbackDirective,
        OrganisationUnitTreeDropDownComponent,
        TrainingGroupDropDownComponent,
        SpinnerOverlayComponent,
        ComponentLoaderComponent,
        GoogleMapsComponent,
        EventLocationComponent,
        IntegrationsComponent,
        WebexComponent,
        TrainingOverviewComponent,
        AreTrainingDatesDifferentDaysPipe,
        FirstStartDatePipe,
        LastEndDatePipe,
        TrainingDatePipe,
        TrainingDateSelectionComponent,
        CompletedEventTableComponent,
        TrainingTypeDisplayPipe,
        DownloadComponent,
        AddExternalCertificateDialogComponent,
        ConfirmationDialogComponent,
        GotowebinarComponent,
        TrainingCalendarComponent,
        TrainingMessageComponent,
        ElearningMessageComponent,
        InvitePersonSelectDialogComponent,
        IsTrainingDateDifferentDaysPipe,
        TrainingDetailEventMemberComponent,
        ImagePickerComponent,
        SideNavComponent,
        PageTitleComponent,
        PageComponent,
        PanelComponent,
        TableComponent,
        EmployeeFormComponent,
        OrganizationUnitFormComponent,
        ExternalContactsOverviewComponent,
        ExternalContactsFormComponent,
        MyTrainingsComponent,
        TrainingInfoComponent,
        CourseInfoComponent,
        TrainingCardComponent,
        ReadMoreComponent,
        AdministrationComponent,
        TrainingGroupsDetailsComponent,
        TrainingCategoriesDetailsComponent,
        TimePickerComponent,
        TrainingFormComponent,
        ManageElearningOverviewComponent,
        EditManageElearningComponent,
        VideoUploaderComponent,
        TrainingEmployeePopupComponent,
        TrainingTemplateFormComponent,
        GeneralSettingsComponent,
        AttendeesListComponent,
        TrainingCompleteComponent,
        TrainingFeedbackComponent,
        ChangePasswordModalComponent,
        TrainingOverviewPopupComponent,
        TrainingCancelDialogComponent,
        CourseQuestionsModalComponent,
        CourseQuestionsComponent,
        CourseQuestionComponent,
        FeedbackComponent,
        MyInvitationComponent,
        AcceptInvitationModalComponent,
        TestComponent,
        LoginComponent,
        ImportDataComponent,
        TrainingCatalogueOverviewComponent,
        TrainingCatalogueCardComponent,
        TrainingCatalogueInfoComponent,
        MergeEmployeesModalComponent,
        TrainingSaveCalendarModalComponent,
        IntegrateWbdModalComponent,
        ReportingIddComponent,
        ReportingComplianceComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientJsonpModule,
        MsalModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatIconModule,
        MatToolbarModule,
        MatListModule,
        MatButtonModule,
        MatTooltipModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        DxButtonModule,
        DxBulletModule,
        DxPopupModule,
        MatRadioModule,
        MatButtonToggleModule,
        DxHtmlEditorModule,
        DxMapModule,
        MatTabsModule,
        ToastrModule.forRoot(),
        MatCheckboxModule,
        OverlayModule,
        MatProgressSpinnerModule,
        NgxMatSelectSearchModule,
        DxTreeListModule,
        DxTreeViewModule,
        DxDataGridModule,
        DxTemplateModule,
        DxTextAreaModule,
        DxDropDownBoxModule,
        DxDropDownButtonModule,
        DxTextBoxModule,
        DxSchedulerModule,
        DxDrawerModule,
        DxCheckBoxModule,
        DxResponsiveBoxModule,
        GoogleMapsModule,
        MatAutocompleteModule,
        DxFileUploaderModule,
        DxFormModule,
        DxProgressBarModule,
        DxSelectBoxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        DxDateBoxModule,
        DxAutocompleteModule,
        MatProgressBarModule,
        MatMenuModule,
        DxMenuModule,
        DxTabsModule,
        FormsModule,
        MatChipsModule,
        SelectablePersonComponent,
        DxNumberBoxModule,
        DxRadioGroupModule,
        DxListModule,
        DxTagBoxModule,
        DxChartModule,
        DxPieChartModule,
        NgxPrintModule,
        DxGalleryModule,
        DxDateRangeBoxModule,
        DxValidatorModule,
        DxValidationSummaryModule,
        AttendeeListComponent,
        DxToolbarModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {provide: LOCALE_ID, useValue: "de-DE"},

        MsalService,
        MsalGuard,
        MsalBroadcastService,
        TrainingTypeDisplayPipe,
        CookieService,
        DatePipe
    ],
    exports: [OrganisationUnitTreeDropDownComponent],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
    constructor() {
    }
}
