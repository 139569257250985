import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { TrainingService } from '../../../services/training.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { TrainingFullModel } from '../../../models/training/training-full.model';
import { TrainingLocationTypeModel } from '../../../models/training/training-location-type.model';
import { TrainingTypeModel } from '../../../models/training/training-type.model';
import {
  calendarIcon,
  categoryIcon,
  bookmarkIcon,
  bookmarkOutlinedIcon,
} from '../../../app-icons';
import { TranslateService } from '@ngx-translate/core';
import { first, shareReplay, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { AuthorizationService } from '../../../services/authorization.service';
import { PersonEventFlagService } from '../../../services/person-event-flag.service';
import { FileUtilService } from '../../../services/file-util.service';

@Component({
  selector: 'app-training-info',
  templateUrl: './training-info.component.html',
  styleUrls: ['./training-info.component.scss'],
})
export class TrainingInfoComponent {
  trainingId: string;
  TrainingLocationType = TrainingLocationTypeModel;
  TrainingType = TrainingTypeModel;
  training: TrainingFullModel | null = null;
  memberStatus: number | null = null;
  joinUrl: string | null = null;
  isOldTraining: boolean = false;
  isInitialLoad = true;
  isAdmin = false;
  isTrainingCatalogue = false;
  isBookmarked = false;
  isAddedToCalendar = false;
  showSaveCalendarModal = false;

  get locationType() {
    switch (this.training?.training.trainingLocation.type) {
      case TrainingLocationTypeModel.Address:
        return this.translateService.instant(
          'COMMON.FIELDS.EVENT-TYPES.FACE-TO-FACE'
        );
      case TrainingLocationTypeModel.Web:
        return this.translateService.instant(
          'COMMON.FIELDS.EVENT-TYPES.ONLINE'
        );
      case TrainingLocationTypeModel.GoToWebinar:
        return 'GoToWebinar';
      case TrainingLocationTypeModel.Webex:
        return 'Webex';
      default:
        return '';
    }
  }

  get trainingType() {
    switch (this.training?.training.trainingType) {
      case TrainingTypeModel.General:
        return this.translateService.instant(
          'COMMON.FIELDS.TRAINING-TYPES.GENERAL'
        );
      case TrainingTypeModel.Compliance:
        return this.translateService.instant(
          'COMMON.FIELDS.TRAINING-TYPES.COMPLIANCE'
        );
      case TrainingTypeModel.IDD:
        return this.translateService.instant(
          'COMMON.FIELDS.TRAINING-TYPES.IDD'
        );
      default:
        return '';
    }
  }

  get trainingGroups() {
    if (!this.training?.trainingGroups.length) {
      return '';
    } else {
      return this.training?.trainingGroups
        .map((group) => group.name)
        .join(', ');
    }
  }

  get durationString() {
    if (
      !this.training ||
      !this.training?.training ||
      typeof this.training?.training.iddTimeMinutes !== 'number' ||
      isNaN(this.training?.training.iddTimeMinutes)
    ) {
      return '00:00 h';
    }

    const hours = Math.floor(this.training?.training.iddTimeMinutes / 60);
    const remainingMinutes = this.training?.training.iddTimeMinutes % 60;
    const durationString = `${hours}:${
      remainingMinutes < 10 ? '0' : ''
    }${remainingMinutes} h`;

    return durationString;
  }

  get trainingCategories() {
    if (
      this.training &&
      this.training.trainingCategories &&
      this.training.trainingCategories.length
    ) {
      return this.training.trainingCategories
        .map((category) => category.name)
        .join(', ');
    } else {
      return null;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private trainingService: TrainingService,
    private personEventFlagService: PersonEventFlagService,
    private loadingService: LoadingService,
    private router: Router,
    private toastrService: ToastrService,
    public translateService: TranslateService,
    private location: Location,
    private authorizationService: AuthorizationService,
    private sanitized: DomSanitizer,
    private cookieService: CookieService,
    private fileUtilService: FileUtilService
  ) {
    this.isTrainingCatalogue = !!this.route.snapshot.params['categoryId'];
    this.trainingId = this.route.snapshot.params['id'];
    this.loadTrainingData();
  }

  getCalendarIcon() {
    return this.sanitized.bypassSecurityTrustHtml(calendarIcon);
  }

  getCategoryIcon() {
    return this.sanitized.bypassSecurityTrustHtml(categoryIcon);
  }

  getBookmarkIcon() {
    return this.sanitized.bypassSecurityTrustHtml(bookmarkIcon);
  }

  getBookmarkOutlinedIcon() {
    return this.sanitized.bypassSecurityTrustHtml(bookmarkOutlinedIcon);
  }

  loadTrainingData(): void {
    const trainingData = this.loadingService
      .load(
        forkJoin({
          training: this.trainingService.getTraining(this.trainingId),
          memberStatus: this.trainingService.getTrainingMemberStatus(
            this.trainingId
          ),
          personEventFlags: this.personEventFlagService.getPersonEventFlags(
            this.trainingId
          ),
        })
      )
      .pipe(shareReplay(1));

    trainingData.subscribe(({ training, memberStatus, personEventFlags }) => {
      this.training = training;
      this.memberStatus = memberStatus.status;
      this.joinUrl = memberStatus.joinUrl;
      this.isBookmarked = !!personEventFlags.find(
        (flag) => flag.actionFlag == 1
      );
      this.isAddedToCalendar = !!personEventFlags.find(
        (flag) => flag.actionFlag == 2
      );

      const lastDate = new Date(
        this.training.dates[this.training.dates.length - 1].end
      );
      if (lastDate < new Date()) {
        this.isOldTraining = true;
      }

      if (
        this.isInitialLoad &&
        this.route.snapshot.queryParams['autoaccept'] &&
        this.memberStatus !== 1 &&
        !this.isOldTraining
      ) {
        this.onAcceptInvitationClick();
      }
      this.isInitialLoad = false;

      this.authorizationService
        .getCurrentPerson()
        .pipe(first())
        .subscribe((currentUser) => {
          if (
            currentUser.person.role.name == 'SuperAdmin' ||
            this.training?.trainers.find(
              (trainer) => trainer.id == currentUser.person.id
            )
          ) {
            this.isAdmin = true;
          }
        });
    });
  }

  onAcceptInvitationClick(): void {
    this.loadingService
      .load(this.trainingService.acceptTraining(this.trainingId))
      .subscribe({
        next: () => {
          const expirationDate = new Date();
          expirationDate.setFullYear(expirationDate.getFullYear() + 1);
          this.cookieService.set(
            `training-${this.training?.training.key}`,
            'true',
            expirationDate,
            '/'
          );

          this.loadTrainingData();
          this.toastrService.success(
            this.translateService.instant(
              'MY-TRAININGS.OVERVIEW.ACCEPT-INVITATION-SUCCESSFUL'
            )
          );
          this.showSaveCalendarModal = true;
        },
        error: () =>
          this.toastrService.error(
            this.translateService.instant('COMMON.AN-ERROR-HAS-OCCURRED')
          ),
      });
  }

  onDeclineInvitationClick(): void {
    this.loadingService
      .load(this.trainingService.declineTraining(this.trainingId))
      .subscribe({
        next: () => {
          this.cookieService.delete(
            `training-${this.training?.training.key}`,
            '/'
          );

          this.loadTrainingData();
          this.toastrService.success(
            this.translateService.instant(
              'MY-TRAININGS.OVERVIEW.DECLINE-INVITATION-SUCCESSFUL'
            )
          );
        },
        error: () =>
          this.toastrService.error(
            this.translateService.instant('COMMON.AN-ERROR-HAS-OCCURRED')
          ),
      });
  }

  navigateBack(): void {
    if (this.isTrainingCatalogue) {
      this.router.navigate([
        `/training-catalogue/${this.route.snapshot.params['categoryId']}`,
      ]);
    } else {
      this.router.navigate(['/my-trainings']);
    }
  }

  toggleBookmark() {
    if (this.isBookmarked) {
      this.loadingService
        .load(
          this.personEventFlagService.deletePersonEventFlag(
            this.trainingId,
            'Bookmark'
          )
        )
        .subscribe(() => {
          this.toastrService.success(
            this.translateService.instant(
              'TRAINING-CATALOGUE.UNBOOKMARK-SUCCESS'
            )
          );
          this.isBookmarked = false;
        });
    } else {
      this.loadingService
        .load(
          this.personEventFlagService.addPersonEventFlag(
            this.trainingId,
            'Bookmark'
          )
        )
        .subscribe(() => {
          this.toastrService.success(
            this.translateService.instant('TRAINING-CATALOGUE.BOOKMARK-SUCCESS')
          );
          this.isBookmarked = true;
        });
    }
  }

  addToCalendar() {
    this.loadingService
      .load(
        this.personEventFlagService.addPersonEventFlag(
          this.trainingId,
          'AddToCalendar'
        )
      )
      .subscribe(() => {
        this.toastrService.success(
          this.translateService.instant(
            'MY-TRAININGS.SAVED-TO-CALENDAR-SUCCESSFUL'
          )
        );
        this.isAddedToCalendar = true;
        this.onDownloadIcsClick();
      });
  }

  onAdminViewClick(): void {
    this.router.navigate(['training', this.trainingId]);
  }

  onDownloadIcsClick(): void {
    this.loadingService
      .load(this.trainingService.getIcs(this.trainingId))
      .subscribe({
        next: (ics) => this.fileUtilService.downloadFile(ics),
        error: () => this.toastrService.error('Es ist ein Fehler aufgetreten'),
      });
  }
}
