import {Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild,} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {concatAll, concatMap, filter, forkJoin, from, interval, map, Observable, of, startWith, Subscription, switchMap,} from 'rxjs';
import {CourseContentTypeModel} from 'src/app/models/course/course-content-type.model';
import {CourseTemplateFullModel} from 'src/app/models/course/course-template-full.model';
import {CourseTemplateService} from 'src/app/services/course-template.service';
import {FileUtilService} from 'src/app/services/file-util.service';
import {LoadingService} from 'src/app/services/loading.service';
import {v4 as uuidv4} from 'uuid';

// @ts-ignore
import * as JSZip from 'jszip';
import {ContentFilePreviewModel} from '../../../models/content-file-preview.model';
import {Configuration} from 'src/assets/Configuration';
import {AuthorizationService} from 'src/app/services/authorization.service';
import {RawFileModel} from 'src/app/models/raw-file-model';
import {FileProgressService} from 'src/app/services/file-progress.service';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {FileModel} from "../../../models/file.model";
import {FileUploadService} from "../../../services/file-upload.service";

@Component({
    selector: 'app-video-uploader',
    templateUrl: './video-uploader.component.html',
    styleUrl: './video-uploader.component.scss',
})
export class VideoUploaderComponent implements OnInit {

    @Input() readOnly = false;
    @Input() isExternal = false;
    @Input() courseId!: string;
    @Input() contentFile!: File;
    @Output() contentFileChange = new EventEmitter();
    @Output() isUploading: EventEmitter<boolean> = new EventEmitter<boolean>();

    private videoPlayer!: ElementRef<HTMLVideoElement>;

    @ViewChild('videoPlayer') set content(content: ElementRef<HTMLVideoElement>) {
        if (content) {
            this.videoPlayer = content;
        }
    }

    private subs = new Subscription();
    uploadUrl = Configuration.getApiBaseUrl() + '/fileuploader';
    accessToken: string = '';
    // videoPlayer!: ElementRef<HTMLVideoElement>;
    thumbnailUrl$: Observable<SafeUrl | String | null> = of(null);
    contentFilePreview: ContentFilePreviewModel | null = null;
    CourseContentTypeModel = CourseContentTypeModel;

    fileUploadStatus: string | null = null;
    statusSubscription: Subscription | null = null;
    shouldCall: boolean = false;

    constructor(
        private courseTemplateService: CourseTemplateService,
        private loadingService: LoadingService,
        private toastrService: ToastrService,
        private sanitizer: DomSanitizer,
        private fileUtilService: FileUtilService,
        private authorizationService: AuthorizationService,
        private fileProgressService: FileProgressService,
        private ngZone: NgZone,
        private httpClient: HttpClient,
        private fileUploadService: FileUploadService,
    ) {
        const sub = this.authorizationService.accessToken$
            .pipe(
                filter((accessToken: string | null) => accessToken != null),
                map((accessToken: string | null) => accessToken as string)
            )
            .subscribe((accessToken) => {
                this.accessToken = accessToken;
            });
        this.subs.add(sub);
    }

    ngOnInit(): void {
        if (this.courseId != "new") {
            this.uploadUrl = Configuration.getApiBaseUrl() + '/fileuploader?courseId=' + this.courseId
        }
    }

    startSubscription(fileStatusIdentifier: string) {
        this.stopSubscription();
        this.statusSubscription = interval(1000)
            .pipe(
                startWith(1),
                filter(() => {
                    return this.shouldCall;
                }),
                switchMap(() => {
                    return this.fileProgressService.getStatus(fileStatusIdentifier);
                })
            )
            .subscribe(
                (response) => {
                    this.ngZone.run(() => {
                        this.fileUploadStatus = response ? response : "Reading data...";
                    });
                },
                (error) => {
                    console.error('API error:', error);
                }
            );
    }

    stopSubscription() {
        if (this.statusSubscription) {
            this.statusSubscription.unsubscribe();
            this.statusSubscription = null;
        }
    }

    ngOnDestroy(): void {
        this.stopSubscription();
    }

    onBeforeSend(e: any, accessToken: string): any {

        e.request.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    }

    onUploaded(e: any) {

        if (this.statusSubscription) {
            this.shouldCall = false;
            this.fileUploadStatus = "";
            this.stopSubscription();
        }

        this.onSelectedFileChange([e.file], JSON.parse(e.request.response));
    }

    public setPreview(courseTemplateFull: CourseTemplateFullModel) {
        if (courseTemplateFull.contentFile) {
            const contentFilePreview: ContentFilePreviewModel = {
                contentType: courseTemplateFull.courseTemplate.courseContentType,
                name: courseTemplateFull.contentFile.fileName,
                url: courseTemplateFull.contentFile.url,
            };
            this.contentFilePreview = contentFilePreview;
        }

        this.thumbnailUrl$ = of(courseTemplateFull.courseTemplate.imageUrl);
    }

    onContentDeleteClick() {
        this.contentFilePreview = null;
        this.contentFileChange.emit(null);
    }

    handleUploadStarted(e: any) {
        if (
            e.file.type === 'application/x-zip-compressed' ||
            e.file.type === 'application/zip'
        ) {
            // pass the variable to parent while uploading
            this.isUploading.emit(true);
            this.shouldCall = true;
        }
    }


    async onSelectedFileChange(
        files: File[],
        response: { fileUrl: string; fileId: string; fileName: string }
    ) {
        if (files.length > 0) {
            const obs = this.courseTemplateService.getCourseContentTypeByFileName(
                files[0].name
            );
            this.loadingService.load(obs).subscribe((contentType) => {
                if (contentType == CourseContentTypeModel.Unknown) {
                    this.toastrService.error(
                        'Der gewählte Dateityp wird nicht unterstützt'
                    );
                    return;
                }

                this.contentFilePreview = {
                    contentType: contentType,
                    name: response.fileName,
                    url: response.fileUrl,
                };
                this.contentFile = files[0];
                this.thumbnailUrl$ = this.getPreviewImageSafeUrl();
                this.loadingService
                    .load(this.getContentFilePreviewImage$())
                    .subscribe((result) => {
                        this.contentFileChange.emit({
                            fileId: response.fileId,
                            file: files[0],
                            preview: result,
                        });
                    });
            });
        } else {
            this.contentFilePreview = null;
        }
        //pass the variable to parent after upload
        this.isUploading.emit(false);

    }

    private getPreviewImageSafeUrl(): Observable<SafeUrl | null> {
        return this.getPreviewImage().pipe(
            concatMap((file) => {
                if (file) {
                    const unsafeUrl = URL.createObjectURL(file);
                    const safeUrl =
                        this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
                    return of(safeUrl);
                } else {
                    return of(null);
                }
            })
        );
    }

    public getPreviewImage(): Observable<File | null> {

        if (!this.contentFilePreview) {
            return of(null);
        }

        if (
            this.contentFilePreview.contentType == CourseContentTypeModel.AzureVideo
        ) {
            const video = document.createElement('video');
            video.crossOrigin = 'Anonymous';
            video.src = this.contentFilePreview.url as string;

            return new Observable<File | null>((observer) => {
                video.addEventListener('loadeddata', () => {
                    video.currentTime = 1;
                });

                video.addEventListener('seeked', () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    const context = canvas.getContext('2d');
                    context!.drawImage(video, 0, 0, canvas.width, canvas.height);

                    const file = this.fileUtilService.convertDataUrlToFile(
                        canvas.toDataURL('image/jpeg'),
                        'preview.jpg'
                    );
                    observer.next(file);
                    observer.complete();
                });
            });
        }
        if (
            this.contentFilePreview.contentType == CourseContentTypeModel.AzureScorm
        ) {
            if (!this.contentFile) {
                return of(null);
            }
            const zip = new JSZip();
            const obs = from(zip.loadAsync(this.contentFile)).pipe(
                concatMap((filesZip) =>
                    from(
                        filesZip.file('imsmanifest.xml')!.async('string') as Promise<string>
                    )
                ),
                concatMap((imsManifest: string) =>
                    this.courseTemplateService.getScormThumbnailByImsManifestXml(
                        imsManifest
                    )
                ),
                concatMap((thumbnailPath) =>
                    forkJoin({
                        path: of(thumbnailPath),
                        blob: from(zip.file(this.removeSpacesFromThumnailFileName(thumbnailPath) || '')?.async('blob') || '') ?? Promise.resolve(null),
                    })
                ),
                concatMap((file: { path: string | null; blob: any }) => {
                    const extension: string = file.path!.split('.').pop()!;
                    return of(new File([file.blob], 'preview.' + extension));
                })
            );

            return this.loadingService.load(obs);
        }
        return of(null);
    }

    getContentFilePreviewImage$(): Observable<RawFileModel | null> {
        let previewImage$: Observable<RawFileModel | null> = of(null);

        previewImage$ = this.getPreviewImage().pipe(
            filter(previewImageFile => !!previewImageFile),
            switchMap((previewImageFile) =>
                this.fileUtilService.convertFileToRawFileModel(previewImageFile as File)
            )
        );

        return previewImage$;
    }

    removeSpacesFromThumnailFileName(path: any): string {
        return path.replace(/%20/g, ' ');
    }

    onFileChange(ev: Event) {
        const input = (ev.target as HTMLInputElement)
        const file = input.files && input.files.length > 0 ? input.files[0] : null;
        if (!file) {
            return;
        }


        if (!file.name.toLowerCase().endsWith(".zip")) { //Not a Zip. Regular file upload
            const uploadStatusIdentifier = uuidv4();

            this.shouldCall = false;
            this.startSubscription(uploadStatusIdentifier);
            this.fileUploadService.uploadFile(file, uploadStatusIdentifier).pipe(
                tap(httpEvent => {
                    console.log(httpEvent);
                    if (httpEvent.type == HttpEventType.UploadProgress) {
                        const progressEvent = httpEvent as HttpProgressEvent;
                        const percentage = (progressEvent.loaded / (progressEvent.total ? progressEvent.total : 100000000) * 100);
                        this.fileUploadStatus = percentage.toFixed(1) + '%';
                        if (percentage > 99) {
                            this.shouldCall = true;
                        }
                    }
                    if (httpEvent.type == HttpEventType.Response) {
                        const responseEvent = httpEvent as HttpResponse<FileModel>;
                        this.stopSubscription();
                        if (responseEvent.body) {
                            this.onSelectedFileChange([file], responseEvent.body!);
                        }
                    }
                })
            ).subscribe();
        } else { //File is a (scorm) zip. Extract all files in the client, create a folder on the server and upload each file seperatly
            let uploadedCount = 0;
            let totalFilesCount = 0;

            const zip = new JSZip();
            this.fileUploadStatus = 'Unzipping file...'
            this.fileUploadService.createFolder({name: file.name, size: file.size}).pipe(
                switchMap(folder => forkJoin([of(folder), from(zip.loadAsync(file))])),
                map(([folder, jsZip]) => {
                    const entries: { zipObject: JSZip.JSZipObject, folder: FileModel }[] = [];
                    jsZip.forEach((path: string, jsZipObject: JSZip.JSZipObject) => {
                        entries.push({zipObject: jsZipObject, folder: folder});
                    })
                    return entries;
                }),
                tap(entries => {
                    this.fileUploadStatus = 'Unzipped file...';
                    totalFilesCount = entries.length;
                }),
                concatAll(),
                concatMap(toUpload => {
                    return forkJoin([of(toUpload.folder), of(toUpload.zipObject), from(toUpload.zipObject.async("blob"))]);
                }),
                concatMap(([folder, zipObject, blob]) => {
                    uploadedCount += 1;
                    this.fileUploadStatus = 'Uploaded ' + uploadedCount + ' / ' + totalFilesCount + ' files';
                    return this.fileUploadService.uploadToFolder(folder.fileId, zipObject.name, blob);
                }),
                filter((event: HttpEvent<FileModel>) => event.type == HttpEventType.Response),
                map(ev => ev as HttpResponse<FileModel>)
            ).subscribe({
                next: response => {
                    if (uploadedCount == totalFilesCount) {
                        this.fileUploadStatus = 'Upload complete';
                        this.onSelectedFileChange([file], response.body!);
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.toastrService.error(err.status + " " + err.message);
                },
            });
        }
    }
}
